import commonStyles from '../../styles/common.module.css'
import { IMGHOST } from '../../utils/ENV'
import { useRouter } from 'next/router'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import Image from 'next/image'

const AdvertisementBox = props => {
  const router = useRouter()
  return (
    <div
      className={`${router.pathname === '/' ? commonStyles.advertisement_box : commonStyles.advertisement_box_1
        }`}
    >
      <div style={{ position: 'relative' }}>
        {props.data.length < 3 && props.data.length !== 0 && (
          <div>
            <Image
              width={20}
              height={20}
              className={commonStyles.advertisement_img}
              onClick={() => {
                window.open(`${props.data[0].link}`)
              }}
              src={IMGHOST + props.data[0].img}
              alt={props.data[0].title}
            />
            <div className={commonStyles.advertisement_title}>
              <div className={commonStyles.text_over}>{props.data[0].title}</div>
            </div>
          </div>
        )}
        {props.data.length >= 3 && (
          <Swiper modules={[Autoplay, Pagination]} direction="horizontal" autoplay loop>
            {props.data.slice(0, 3)?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Image
                    width={20}
                    height={20}
                    className={commonStyles.advertisement_img}
                    onClick={() => {
                      window.open(item.link)
                    }}
                    src={IMGHOST + item.img}
                    alt={item.title}
                  />
                  <div className={commonStyles.advertisement_title}>
                    <div className={commonStyles.text_over}>{item.title}</div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
        {props.data.length !== 0 && (
          <div
            className={commonStyles.advertisement_down}
            onClick={() => {
              props.down()
            }}
          >
            <span>关闭广告</span>
            <i className="iconfont icon-guanbi" style={{ fontSize: '10px', color: 'white' }}></i>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdvertisementBox
