import { useState, useEffect, useRef } from 'react'
import Router from 'next/router'
import TagItem from '../TagItem/TagItem'
import styles from '../../styles/Home/Home.module.css'
import commonStyles from '../../styles/common.module.css'
import errorImg from '../../../public/assets/image/hubeiribao/error.png'
import HotMore from '../common/HotMore'
import AdvertisementBox from '../common/AdvertisementBox'
import WelfareAdvertisementBox from '../common/WelfareAdvertisementBox'
import { friendshipLink } from '@/utils/friendshipLink'
import { parseStampTime } from '../../utils/timeStamp'
import { IMGHOST } from '../../utils/ENV'
import Crypto from '../../utils/crypto'
import Image from 'next/image'

export default function Template1(props) {
  const pagewidth = props.width
  const newsId = props.newsId
  const hot = props.hot
  const [rendList, setRendList] = useState(props.data)
  // 广告
  const { hengDuans, footers, cebianImgs, welfareImgs } = props.imagesList
  // 广告开启关闭状态
  const [advertisementStatus, setAdvertisementStatus] = useState({ value: true })
  // 判断栏目的截断
  const [endVaalue, setEndValue] = useState(props.width >= 1100 ? 6 : 4)
  // 滚动次数
  const scrollNum = useRef(0)

  // 图片错误处理
  const changeImg = env => {
    let img = env.target
    img.src = errorImg.src
    img.onerror = null
  }

  const handleScroll = () => {
    // 加载条件
    // 界面宽度大于1100px，默认先展示5个栏目数据，滚动一次3个为一组
    // 界面宽度小于1100px，默认先展示3个栏目数据，滚动一次2个为一组
    let Y = window.scrollY
    if (pagewidth >= 1100) {
      if (Y > 0.75 * 105 * (3 + scrollNum.current * 5)) {
        setEndValue(5 + 3 * (scrollNum.current + 1))
        scrollNum.current = scrollNum.current + 1
      }
    } else {
      if (Y > 0.75 * (pagewidth / 5) * (5 + scrollNum.current * 5)) {
        setEndValue(3 + 2 * (scrollNum.current + 1))
        scrollNum.current = scrollNum.current + 1
      }
    }
  }

  useEffect(() => {
    if (JSON.stringify(props.imagesList.hengChangs) !== '[]') {
      if (rendList.length) {
        let data = [...rendList]
        let index = data.findIndex(item => item.img)
        if (index === -1) {
          data?.map((item, index) => {
            if (
              index === parseInt(index / 3) * 3 &&
              parseInt(index / 3) <= props.imagesList.hengChangs.slice(1).length - 1
            ) {
              data.splice(parseInt(index / 3) * 3 + parseInt(index / 3) + 3, 0, {
                img: IMGHOST + props.imagesList.hengChangs.slice(1)[parseInt(index / 3)].img,
                link: props.imagesList.hengChangs.slice(1)[parseInt(index / 3)].link,
                title: props.imagesList.hengChangs.slice(1)[parseInt(index / 3)].title,
              })
            }
          })
          setRendList(data)
        } else {
          setRendList(data)
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
    // 清理函数，在组件卸载时移除事件监听器
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [props.imagesList])

  return (
    <div style={{ paddingTop: '0.2rem' }}>
      {hengDuans.length !== 0 && (
        <div className={commonStyles.content_top_img}>
          {hengDuans?.map((item, index) => {
            return (
              <Image width={20} height={20}
                key={index}
                src={IMGHOST + item.img}
                alt={item.title}
                onClick={() => {
                  window.open(`${item.link}`)
                }}
                onError={env => {
                  changeImg(env)
                }}
              />
            )
          })}
        </div>
      )}
      {/* 热点 */}
      {hot.length !== 0 && (
        <div className={styles.template_hot}>
          {hot?.map((item, index) => {
            return (
              <div key={index} className={styles.template_hot_item}>
                <div
                  className={styles.template_hot_item_name}
                  onClick={() => {
                    let data = {
                      articleId: item.article.id,
                      newsId: newsId,
                    }
                    let data1 = Crypto.Encrypt(JSON.stringify(data))
                    Router.push({
                      pathname: '/article',
                      query: {
                        data: data1,
                      },
                    })
                  }}
                >
                  {item.article.name}
                </div>
                <div className={styles.template_hot_title}>
                  <span>置顶</span>
                  <span>{parseStampTime(item.article.create_time)}</span>
                </div>
              </div>
            )
          })}
        </div>
      )}
      {hot.length > 6 && <HotMore newsId={newsId} />}
      {/* 新闻容器 */}
      {rendList.length !== 0 && (
        <div className={styles.body}>
          {rendList?.map((item, index) => {
            return (
              index < endVaalue && (
                <div
                  key={item.id ? item.id : item.img}
                  className={`${item.img !== undefined ? styles.advertisement_item : ''}`}
                >
                  {item.img === undefined && <TagItem tag={item} newsId={newsId} width={props.width} />}
                  {item.img !== undefined && (
                    <Image width={20} height={20}
                      src={item.img}
                      alt={item.title}
                      onClick={() => {
                        window.open(`${item.link}`)
                      }}
                      onError={env => {
                        changeImg(env)
                      }} />
                  )}
                </div>
              )
            )
          })}
        </div>
      )}
      {rendList.length === 0 && hot.length === 0 && (
        <div className={commonStyles.not_data}>暂无数据</div>
      )}
      <div
        className={styles.bottom_blank}
        style={{
          opacity: pagewidth > 648 ? 0 : 1,
        }}
      >
        {'没有更多了'}
      </div>
      <WelfareAdvertisementBox width={props.width} data={welfareImgs} />
      {/* 友情链接 */}
      {friendshipLink.length !== 0 && (
        <div className={styles.friend_ship}>
          <div className={styles.friend_ship_box}>
            {friendshipLink?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.friend_ship_item}
                  onClick={() => {
                    window.open(item.link)
                  }}
                >
                  {item.name}
                </div>
              )
            })}
          </div>
        </div>
      )}
      {footers.length !== 0 && (
        <div className={styles.footer_images}>
          {footers.slice(0, 4)?.map((item, index) => {
            return (
              <Image
                width={20}
                height={20}
                loading='lazy'
                key={index}
                src={IMGHOST + item.img}
                alt={item.title}
                onClick={() => {
                  window.open(item.link)
                }}
                onError={env => {
                  changeImg(env)
                }}
              />
            )
          })}
        </div>
      )}
      {/* 广告 */}
      {pagewidth > 1100 && cebianImgs.length !== 0 && advertisementStatus.value === true && (
        <AdvertisementBox
          data={cebianImgs}
          down={() => {
            setAdvertisementStatus({ value: false })
          }}
        />
      )}
    </div>
  )
}
