import Template1 from './tenplates/template1'
import dynamic from 'next/dynamic'

export default function templates(props) {
  // 默认为1模板
  // 其他模板动态导入
  const Template2 = dynamic(() => import('./tenplates/template2'))
  const Template3 = dynamic(() => import('./tenplates/template3'))
  const Template4 = dynamic(() => import('./tenplates/template4'))
  const Template5 = dynamic(() => import('./tenplates/template5'))
  const Template7 = dynamic(() => import('./tenplates/template7'))
  const Template8 = dynamic(() => import('./tenplates/template8'))
  const Template9 = dynamic(() => import('./tenplates/template9'))
  const Template10 = dynamic(() => import('./tenplates/template10'))
  const Template11 = dynamic(() => import('./tenplates/template11'))
  const Template12 = dynamic(() => import('./tenplates/template12'))
  const Template13 = dynamic(() => import('./tenplates/template13'))
  const Template14 = dynamic(() => import('./tenplates/template14'))
  const Template15 = dynamic(() => import('./tenplates/template15'))
  const Template16 = dynamic(() => import('./tenplates/template16'))
  const Template17 = dynamic(() => import('./tenplates/template17'))
  const Template18 = dynamic(() => import('./tenplates/template18'))
  const Template19 = dynamic(() => import('./tenplates/template19'))
  const Template20 = dynamic(() => import('./tenplates/template20'))
  const Template21 = dynamic(() => import('./tenplates/template21'))
  const Template22 = dynamic(() => import('./tenplates/template22'))
  const Template23 = dynamic(() => import('./tenplates/template23'))
  const Template24 = dynamic(() => import('./tenplates/template24'))
  const Template26 = dynamic(() => import('./tenplates/template26'))
  const Template27 = dynamic(() => import('./tenplates/template27'))
  const Template28 = dynamic(() => import('./tenplates/template28'))
  const Template29 = dynamic(() => import('./tenplates/template29'))
  const Template30 = dynamic(() => import('./tenplates/template30'))
  const Template31 = dynamic(() => import('./tenplates/template31'))
  const Template32 = dynamic(() => import('./tenplates/template32'))
  const Template33 = dynamic(() => import('./tenplates/template33'))
  const Template34 = dynamic(() => import('./tenplates/template34'))
  const Template35 = dynamic(() => import('./tenplates/template35'))
  const Template36 = dynamic(() => import('./tenplates/template36'))
  const Template37 = dynamic(() => import('./tenplates/template37'))

  return (
    <div>
      {props.id === 1 && (
        <Template1
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 2 && (
        <Template2
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 3 && (
        <Template3
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 4 && (
        <Template4
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 5 && (
        <Template5
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 7 && (
        <Template7
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 8 && (
        <Template8
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 9 && (
        <Template9
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 10 && (
        <Template10
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 11 && (
        <Template11
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 12 && (
        <Template12
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 13 && (
        <Template13
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 14 && (
        <Template14
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 15 && (
        <Template15
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 16 && (
        <Template16
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 17 && (
        <Template17
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 18 && (
        <Template18
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 19 && (
        <Template19
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 20 && (
        <Template20
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 21 && (
        <Template21
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 22 && (
        <Template22
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 23 && (
        <Template23
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 24 && (
        <Template24
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {/* 此模板已废弃 */}
      {/* {props.id === 25 && (
        <Template25
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )} */}
      {props.id === 26 && (
        <Template26
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 27 && (
        <Template27
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 28 && (
        <Template28
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 29 && (
        <Template29
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 30 && (
        <Template30
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 31 && (
        <Template31
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 32 && (
        <Template32
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 33 && (
        <Template33
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 34 && (
        <Template34
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 35 && (
        <Template35
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {props.id === 36 && (
        <Template36
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
      {/* 此模板为人力资源网正式模板不需要侧边和宣传广告 */}
      {props.id === 25 && (
        <Template37
          data={props.data}
          width={props.width}
          imagesList={props.imagesList}
          hot={props.hot}
          newsId={props.newsId}
        />
      )}
    </div>
  )
}
