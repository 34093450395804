export const getImg = imgUrl => {
  let data = new Promise(function (resolve, reject) {
    let newImage = new Image()
    newImage.src = imgUrl
    newImage.onload = () => {
      resolve(newImage)
    }
    newImage.onerror = err => {
      reject(err)
    }
  })
  return data
}

export const getNewUrl = url => {
  let newUrl = undefined
  if (url === null || url === undefined) return
  if (url.indexOf('media') > -1) {
    newUrl = url.substring(url.indexOf('media') + 6)
    return newUrl
  } else {
    return url
  }
}

// 广告处理
export const newAdvertise = (value, id) => {
  let data = {
    hengChangs: [],
    hengDuans: [],
    cebianImgs: [],
    footers: [],
    welfareImgs: [],
  }
  // 客户端
  if (value === undefined || value === null) {
    return data
  } else {
    if (id === undefined || id === '') {
      return data
    } else {
      // 服务端
      if (JSON.stringify(value) !== '[]') {
        try {
          let arr = value.filter(item => item.news.indexOf(Number(id)) > -1)
          if (arr === null) {
            return data
          } else {
            arr?.map(item => {
              item.img = getNewUrl(item.img)
              switch (JSON.parse(item.info).type) {
                case 0:
                  data.hengChangs.push({ ...item })
                  break
                case 1:
                  data.hengDuans.push({ ...item })
                  break
                case 2:
                  data.cebianImgs.push({ ...item })
                  break
                case 3:
                  data.footers.push({ ...item })
                  break
                case 4:
                  data.welfareImgs.push({ ...item })
                  break
              }
              data.hengChangs = data.hengChangs.reverse()
              data.hengDuans = data.hengDuans.slice(0, 2)
            })
            return data
          }
        } catch {
          return data
        }
      } else {
        return data
      }
    }
  }
}
