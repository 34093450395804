import styles from '../../styles/welfareAdvertisement.module.css'
import { IMGHOST } from '../../utils/ENV'
import { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import Image from 'next/image'

const WelfareAdvertisementBox = props => {
  const [pagewidth, setPageWidth] = useState(props.width)

  useEffect(() => {
    if (props.width !== 0) {
      setPageWidth(props.width)
    }
  }, [props.width])

  return (
    <div className={`${styles.wefare_body} wefare_body`}>
      <div className={styles.wefare_title}>公益宣传</div>
      {props.data.length === 0 && <div className={styles.not_wefare}>暂无公益活动</div>}
      {props.data.length !== 0 && (
        <div style={{ marginBottom: '0.1rem' }}>
          {props.data.length === 1 && (
            <div className={styles.wefare_content}>
              {props.data?.map((item, index) => {
                return (
                  <div key={index}>
                    <Image width={20} height={20} alt={item.title} className={styles.wefare_img} src={IMGHOST + item.img} />
                    <div className={styles.wefare_img_title}>{item.title}</div>
                  </div>
                )
              })}
            </div>
          )}
          {props.data.length > 1 && (
            <Swiper
              spaceBetween={50}
              slidesPerView={
                pagewidth >= 1100
                  ? 6
                  : pagewidth >= 800 && pagewidth < 1100
                    ? 4
                    : pagewidth >= 600 && pagewidth < 800
                      ? 3
                      : 2
              }
              modules={[Autoplay, Pagination]}
              direction="horizontal"
              autoplay
              loop
            >
              {props.data?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Image width={20} height={20} alt={item.title} className={styles.wefare_img} src={IMGHOST + item.img} />
                    <div
                      className={styles.wefare_img_title}
                      onClick={() => {
                        window.open(`${item.link}`)
                      }}
                    >
                      {item.title}
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          )}
        </div>
      )}
    </div>
  )
}

export default WelfareAdvertisementBox
