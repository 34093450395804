import React, { useState } from 'react'
import style from '../../styles/TagItem/TagItem1.module.css'
import commonStyles from '../../styles/common.module.css'
import Router from 'next/router'
import { parseStampTime } from '../../utils/timeStamp'
import Crypto from '../../utils/crypto'
import errorImg from '../../../public/assets/image/hubeiribao/error.png'

const TagItem = props => {
  const [articles, setArticles] = useState([...props.tag.article_set])

  // 鼠标移入事件
  const omMouseEnterOrLeave = (pk, type) => {
    let data = [...articles]
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === pk) {
        data[i].isEnter = type
      }
    }
    setArticles(data)
  }

  // 图片错误处理
  const changeImg = env => {
    let img = env.target
    img.src = errorImg.src
    img.onerror = null
  }

  return (
    <div>
      <div className={style.tag_item_head}>
        <div
          className={style.tag_item_head_name}
          onClick={() => {
            let data = {
              articleId: props.tag.id,
              newsId: props.newsId,
            }
            let data1 = Crypto.Encrypt(JSON.stringify(data))
            Router.push({
              pathname: '/column_article',
              query: {
                data: data1,
              },
            })
          }}
        >
          {props.tag.name}
        </div>
      </div>
      {articles.length !== 0 && (
        <div>
          {articles.slice(0, 5)?.map(item => {
            return (
              <div key={item.id} className={style.tag_item_list_item}>
                <div className={style.tag_item_oneimg}>
                  <div
                    style={{
                      color: item.isEnter ? '#FF682C' : '#686868',
                    }}
                    className={commonStyles.text_over_2}
                    onMouseEnter={() => {
                      omMouseEnterOrLeave(item.id, true)
                    }}
                    onMouseLeave={() => {
                      omMouseEnterOrLeave(item.id, false)
                    }}
                    onClick={() => {
                      let data = {
                        articleId: item.id,
                        tagId: props.tag.id,
                        newsId: props.newsId,
                      }
                      let data1 = Crypto.Encrypt(JSON.stringify(data))
                      Router.push({
                        pathname: `/article`,
                        query: {
                          data: data1,
                        },
                      })
                    }}
                  >
                    {item.name}
                  </div>
                  <div className={style.tag_item_list_time}>
                    <span>发布于{parseStampTime(item.create_time)}</span>
                  </div>
                </div>
                {item.imgList.length !== 0 && (
                  <img
                    className={style.tag_item_oneimg_style}
                    alt={item.name}
                    onError={env => {
                      changeImg(env)
                    }}
                    src={item.imgList[0]}
                  />
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default TagItem
