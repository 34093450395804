export const friendshipLink = [
  { name: '中国新闻网', link: 'https://www.chinanews.com/' },
  {
    name: '科技日报',
    link: 'http://digitalpaper.stdaily.com/http_www.kjrb.com/kjrb/html/2023-08/01/node_2.htm',
  },
  { name: '中国旅游报', link: 'http://www.ctnews.com.cn/paper/202202/25/node_01.html' },
  { name: '中国环保报', link: 'http://epaper.cenews.com.cn/html/2023-08/01/node_2.htm' },
  { name: '中国建设报', link: 'http://www.chinajsb.cn/' },
  { name: '中国商报', link: 'https://timg.zgswcn.com/zgsb/html/2023-07/28/node_2.htm' },
  {
    name: '农民日报',
    link: 'https://szb.farmer.com.cn/2023/20230801/20230801_001/20230801_001.html',
  },
  {
    name: '知识产权报',
    link: 'http://epaper.iprchn.com/zscqb/html/2023-04/12/node_27586.htm',
  },
  { name: '消费日报', link: 'http://dzb.xfrb.com.cn/Html/2023-07-31/Qpaper.html' },
  { name: '中国电影报', link: 'http://chinafilmnews.cn/Html/2023-07-26/Qpaper.html' },
  {
    name: '中国体育报',
    link: 'http://www.chinasportsdaily.cn/tyb/html/2023-08/01/node_1.htm?v=1',
  },
  { name: '经济日报', link: 'http://paper.ce.cn/pc/layout/202308/01/node_01.html' },
  { name: '健康报', link: 'https://faxing.jkb.com.cn/' },
  { name: '中国教育报', link: 'http://paper.jyb.cn/zgjyb/html/2022-09/19/node_2.htm' },
  { name: '中国江苏网', link: 'https://www.jschina.com.cn/' },
  { name: '新华报业网', link: 'https://www.xhby.net/' },
  { name: '扬子晚报网', link: 'https://www.yangtse.com/' },
  { name: '千龙网', link: 'https://www.qianlong.com/' },
  { name: '东方网', link: 'https://www.eastday.com/' },
  { name: '南方网', link: 'https://www.southcn.com/' },
  { name: '西部网', link: 'http://www.cnwest.com/' },
  { name: '大众网', link: 'https://www.dzwww.com/' },
  { name: '浙江在线', link: 'https://www.zjol.com.cn/' },
  { name: '四川新闻网', link: 'http://www.newssc.org/' },
  { name: '河北新闻网', link: 'https://www.hebnews.cn/' },
  { name: '每日甘肃网', link: 'https://www.gansudaily.com.cn/' },
  { name: '红网', link: 'https://www.rednet.cn/' },
  { name: '中国吉林网', link: 'https://www.cnjiwang.com/' },
  { name: '东北新闻网', link: 'http://www.nen.com.cn/' },
  { name: '黄河新闻网', link: 'https://www.sxgov.cn/' },
  { name: '华龙网', link: 'https://www.cqnews.net/' },
  { name: '东南网', link: 'https://www.fjsen.com/' },
  { name: '南海网', link: 'https://www.hinews.cn/index.shtml' },
  { name: '云南网', link: 'https://www.yunnan.cn/' },
  { name: '北国网', link: 'https://www.lnd.com.cn/' },
  { name: '大江网', link: 'https://www.jxcn.cn/' },
  { name: '中安在线', link: 'https://www.anhuinews.com/' },
  { name: '宁夏新闻网', link: 'https://www.nxnews.net/' },
  { name: '西藏新闻网', link: 'https://www.xzxw.com/' },
  { name: '大连天健网', link: 'https://www.runsky.com/' },
  { name: '中国江西网', link: 'https://www.jxnews.com.cn/' },
  { name: '广西新闻网', link: 'https://www.gxnews.com.cn/' },
  { name: '山西新闻网', link: 'http://www.sxrb.com/' },
  { name: '齐鲁网', link: 'https://www.iqilu.com/' },
  { name: '东北网', link: 'https://www.dbw.cn/' },
  { name: '长城网', link: 'https://www.hebei.com.cn/' },
  { name: '天山网', link: 'https://www.ts.cn/' },
  { name: '荆楚网', link: 'http://www.cnhubei.com/' },
  { name: '大河网', link: 'https://www.dahe.cn/' },
  { name: '多彩贵州网', link: 'http://www.gog.cn/' },
  { name: '青海新闻网', link: 'http://www.qhnews.com/' },
  { name: '深圳新闻网', link: 'https://www.sznews.com/' },
  { name: '内蒙古新闻网', link: 'http://www.nmgnews.com.cn/' },
  { name: '中国甘肃网', link: 'https://www.gscn.com.cn/' },
  { name: '中国山东网', link: 'https://www.sdchina.com/' },
  { name: '黑龙江网', link: 'https://www.chinahlj.cn/' },
  { name: '人民网', link: 'http://www.people.com.cn/' },
  { name: '中国网', link: 'http://www.china.com.cn/' },
  { name: '中国经济网', link: 'http://www.ce.cn/' },
  { name: '中国广播网', link: 'https://www.cnr.cn/' },
  { name: '中国网络电视台', link: 'https://www.cctv.com/' },
  { name: '国际在线', link: 'https://www.cri.cn/' },
  { name: '共产党员网', link: 'https://www.12371.cn/' },
  { name: '新华网', link: 'http://www.xinhuanet.com//' },
  { name: '中国日报网', link: 'https://www.chinadaily.com.cn/' },
  { name: '光明网', link: 'https://www.gmw.cn/' },
  { name: '中新网', link: 'https://www.chinanews.com/' },
  { name: '中青网', link: 'https://www.youth.cn/' },
  { name: '中国台湾网', link: 'http://www.taiwan.cn/' },
  { name: '中国西藏网', link: 'http://www.tibet.cn/' },
  { name: '海外网', link: 'https://www.haiwainet.cn/' },
  { name: '中国搜索', link: 'https://www.chinaso.com/' },
  { name: '北方网', link: 'http://www.enorth.com.cn/' },
]
