// next
import { BASEHOST } from '@/utils/ENV'
import { useState, useEffect } from 'react'
import Head from 'next/head'
// 组件
import RouterLodingDemo from '@/components/common/RouterLodingDemo'
import Templates from '@/components/templates'
import Navbars from '@/components/navbars'
// 公共方法
import { getNewColumuList, getRendList } from '@/utils/handleColumu'
import { setKeys } from '@/utils/setKeys'
import { newAdvertise } from '@/utils/getImg'
import Router, { useRouter } from 'next/router'

const NodeCache = require("node-cache");
// navbar相关
const mainCache = new NodeCache({ stdTTL: 120 }); // 缓存有效期1分钟
// 与热点相关
const hotCache = new NodeCache({ stdTTL: 120 }); // 缓存有效期2分钟
// 与广告相关
const advCache = new NodeCache({ stdTTL: 180 }); // 缓存有效期3分钟
// 专用于id缓存
const idCache = new NodeCache({ stdTTL: 84600 }); // 缓存一天

function Home({ data }) {
  // 界面宽度
  const [pagewidth, setPageWidth] = useState(0)
  // 加载状态
  const [lodingStatus, setLodingStatus] = useState(true)
  // 基本数据
  const [newsInfo, setNewsInfo] = useState(JSON.stringify(data) !== '{}' ? data.newsInfo : {}) // 网站信息
  const [newData, setNewData] = useState(JSON.stringify(data) !== '{}' ? data.rendList : []) // 网页主内容
  const [columuList, setColumuList] = useState(JSON.stringify(data) !== '{}' ? data.columuList : []) // 导航栏目列表
  const [newsId, setNewsId] = useState(JSON.stringify(data) !== '{}' ? data.newsId : 0) // 网站ID
  const [hot, setHot] = useState(JSON.stringify(data) !== '{}' ? data.hot : []) // 热点
  // 广告
  const [imagesList, setImagesList] = useState(JSON.stringify(data) !== '{}' ? data.imagesList : newAdvertise())
  // 定义路由
  const router = useRouter()


  const resizeWidth = () => {
    let h = document.documentElement.clientWidth
    if (h >= 1100) {
      document.documentElement.style.fontSize = '105px'
    } else if (h >= 1100 && h > 600) {
      document.documentElement.style.fontSize = 'calc(100vw / 5)'
    } else {
      document.documentElement.style.fontSize = 'calc(100vw / 3.75)'
    }
    setPageWidth(h)
  }

  /* 2.函数调用 */
  useEffect(() => {
    if (JSON.stringify(data) !== '{}') {
      if (localStorage.getItem(`${data.newsId}`) === null) {
        localStorage.setItem(`${data.newsId}`, JSON.stringify(data))
      }
      if (localStorage.getItem('id') === null) {
        localStorage.setItem('id', data.newsId)
      } else {
        let _id = Number(localStorage.getItem('id'))
        if (_id !== Number(data.newsId)) {
          localStorage.setItem('id', data.newsId)
          localStorage.removeItem(`${_id}`)
        }
      }
      setLodingStatus(false)
    } else {
      if (process.env.NODE_ENV === 'development') {
        let search = location.search
        if (search === '') {
          router.push({
            pathname: '/error',
            status: 404
          })
        } else if (search.split('=')[1] === '') {
          router.push({
            pathname: '/error',
            status: 500
          })
        }
      } else {
        let id = localStorage.getItem('id')
        if (id === null) {
          location.reload();
        } else {
          if (localStorage.getItem(`${id}`) !== null) {
            let _data = JSON.parse(localStorage.getItem(`${id}`))
            setNewsInfo(_data.newsInfo)
            setNewData(_data.rendList)
            setColumuList(_data.columuList)
            setNewsId(_data.newsId)
            setHot(_data.hot)
            setImagesList(_data.imagesList)
          } else {
            router.push({
              pathname: '/error',
              status: 500
            })
          }
        }
      }
      setLodingStatus(false)
    }
    let w = document.documentElement.clientWidth
    setPageWidth(w)
    // 防止localStorage被修改
    window.addEventListener('storage', function (e) {
      localStorage.setItem(e.key, e.oldValue)
    })
    // 防止sessionStorage被修改
    window.addEventListener('storage', function (e) {
      sessionStorage.setItem(e.key, e.oldValue)
    })
    // 页面变化时获取浏览器窗口的大小
    window.addEventListener('resize', resizeWidth)
    return () => {
      // 组件销毁时移除监听事件
      window.removeEventListener('resize', resizeWidth)
    }
  }, [])

  return (
    <>
      <RouterLodingDemo status={lodingStatus} />
      <Head>
        <title>{newsInfo.name}</title>
        <meta name="description" content={newsInfo.description} />
        <meta name="keywords" content={newsInfo.keys}></meta>
        <meta charset="UTF-8"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="Cache-Control" content="max-age=7200" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navbars
        columu={columuList}
        newsInfo={newsInfo}
        width={pagewidth}
        news_id={newsId}
        headLogo={imagesList.hengChangs === undefined ? [] : imagesList.hengChangs}
        // 个别模板导航栏需要热点数据
        hot={hot}
      />
      <Templates
        id={newsInfo.template_id}
        data={newData}
        hot={hot}
        width={pagewidth}
        newsId={newsId}
        imagesList={imagesList}
      />
    </>
  )
}

export default Home

/* 在浏览器渲染之前调用  */
/* 在服务器上执行 */
/* context 参数包含了常用的请求的 req、res、params、query 等参数 */
/* 常用于: 页面前置权限校验 页面必备参数获取 */
export async function getServerSideProps({ query, res, req }) {
  /**
   * newsId、template_id、newsInfo、columuList、rendList数据为一组
   * 每一分钟清空
   * hot为一组
   * 每两分钟清空
   * imagesList为一组
   * 每三分钟清空
   * 当每组数据中的值为空时请求对应数据
   */
  let navbarData = {}
  let hotData = []
  let imagesListData = {
    hengChangs: [],
    hengDuans: [],
    cebianImgs: [],
    footers: [],
    welfareImgs: [],
  }
  let main_data = mainCache.get('data')
  if (!main_data) {
    if (JSON.stringify(query) === '{}') {
      return { props: { data: {} } }
    } else if (query.news_id === '' || query.news_id === undefined || query.news_id === 'undefined') {
      return { props: { data: {} } }
    } else {
      const news_id = query.news_id
      let result1 = await fetch(`${BASEHOST}/newsgetfromid/?news_id=${news_id}&flag=1`)
        .then(res => res.json())
        .then(data => data)
        .catch(err => {
          return false
        })
      if (result1 === false || result1.code !== 200) {
        return { props: { data: {} } }
      } else {
        navbarData.newsId = news_id
        navbarData.template_id = result1.data.template_id
        navbarData.newsInfo = setKeys(result1.data)
        navbarData.columuList = getNewColumuList(result1.data.column_list)
        navbarData.rendList = getRendList(result1.data.column_list)
        mainCache.set('data', JSON.stringify(navbarData))
        idCache.set('id', news_id)
      }
    }
  } else {
    let newData = JSON.parse(main_data)
    if (JSON.stringify(query) === '{}') {
      navbarData = { ...newData }
    } else {
      if (Number(newData.newsId) === Number(query.news_id)) {
        navbarData = { ...newData }
      } else {
        const news_id = query.news_id
        let result1 = await fetch(`${BASEHOST}/newsgetfromid/?news_id=${news_id}&flag=1`)
          .then(res => res.json())
          .then(data => data)
          .catch(err => {
            return false
          })
        if (result1 === false || result1.code !== 200) {
          return { props: { data: {} } }
        } else {
          navbarData.newsId = news_id
          navbarData.template_id = result1.data.template_id
          navbarData.newsInfo = setKeys(result1.data)
          navbarData.columuList = getNewColumuList(result1.data.column_list)
          navbarData.rendList = getRendList(result1.data.column_list)
          mainCache.set('data', JSON.stringify(navbarData))
          idCache.set('id', news_id)
        }
      }
    }
  }
  let hot_data = hotCache.get('data')
  if (!hot_data) {
    let id = idCache.get('id')
    const news_id = JSON.stringify(query) !== '{}' ? query.news_id : id
    let result2 = await fetch(`${BASEHOST}/hotlistv2/?news_id=${news_id}&flag=1`)
      .then(res => res.json())
      .then(data => data)
      .catch(err => {
        return false
      })
    if (result2 !== false && result2.code === 200) {
      hotData = [...result2.data.hot_list]
      hotCache.set('data', JSON.stringify(hotData))
    }
  } else {
    let id = idCache.get('id')
    if (Number(query.id) === Number(id)) {
      let newData = JSON.parse(hot_data)
      hotData = [...newData]
    } else {
      const news_id = JSON.stringify(query) !== '{}' ? query.news_id : id
      let result2 = await fetch(`${BASEHOST}/hotlistv2/?news_id=${news_id}&flag=1`)
        .then(res => res.json())
        .then(data => data)
        .catch(err => {
          return false
        })
      if (result2 !== false && result2.code === 200) {
        hotData = [...result2.data.hot_list]
        hotCache.set('data', JSON.stringify(hotData))
      }
    }
  }
  let img_data = advCache.get('data')
  if (!img_data) {
    let id = idCache.get('id')
    const news_id = JSON.stringify(query) !== '{}' ? query.news_id : id
    let result3 = await fetch(`${BASEHOST}/advertise/?news_id=${news_id}&flag=1`)
      .then(res => res.json())
      .then(data => data)
      .catch(err => {
        return false
      })
    if (result3 !== false && result3.code === 200) {
      imagesListData = { ...newAdvertise(result3.data, news_id) }
      advCache.set('data', JSON.stringify(imagesListData))
    }
  } else {
    let id = idCache.get('id')
    if (Number(id) === Number(query.news_id)) {
      let newData = JSON.parse(img_data)
      imagesListData = { ...newData }
    } else {
      const news_id = JSON.stringify(query) !== '{}' ? query.news_id : id
      let result3 = await fetch(`${BASEHOST}/advertise/?news_id=${news_id}&flag=1`)
        .then(res => res.json())
        .then(data => data)
        .catch(err => {
          return false
        })
      if (result3 !== false && result3.code === 200) {
        imagesListData = { ...newAdvertise(result3.data, news_id) }
        advCache.set('data', JSON.stringify(imagesListData))
      }
    }
  }
  return {
    props: {
      data: {
        newsId: navbarData.newsId,
        template_id: navbarData.template_id,
        newsInfo: navbarData.newsInfo,
        columuList: navbarData.columuList,
        rendList: navbarData.rendList,
        hot: hotData,
        imagesList: imagesListData,
      }
    }
  }
}