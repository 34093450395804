import style from '../../styles/lodingDemo.module.css'

// 点击跳转等待和页面加载等待
const RouterLodingDemo = props => {
  const { status } = props
  return (
    <>
      {status === true && (
        <div className={style.loding_body}>
          <div className={style.loding_box_1}></div>
          <div className={style.loding_title}>页面加载中，请等待...</div>
        </div>
      )}
    </>
  )
}
export default RouterLodingDemo
